import React, { FC } from 'react';

import { Typography } from '@mui/material';

import Button from '~/components/atoms/buttons/Button';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { Typographies } from '~/theme/typography';

import styles from '../../styles.module.scss';

const list = [
  'Your progress and account will not be deleted.',
  'You will not be charged during the pause period.',
  'We will keep you informed when the pause period ends.',
];

type Props = {
  onPauseSub: () => void;
  onCancelSub: () => void;
  onSupport: () => void;
};

const Control: FC<Props> = ({ onPauseSub, onCancelSub, onSupport }) => {
  const { is_stripe_user } = useAppSelector((state) => state.profile);

  return (
    <div>
      {!is_stripe_user && (
        <div className={styles.pause}>
          <Typography
            variant={Typographies.TITLE_MEDIUM}
            component="h4"
            className={styles.pause__title}
          >
            Pause your subscription instead of canceling it:
          </Typography>

          <ul className={styles.pause__list}>
            {list.map((el) => (
              <li className={styles.pause__item} key={el}>
                <Typography variant={Typographies.BODY_MEDIUM} component="p">
                  {el}
                </Typography>
              </li>
            ))}
          </ul>

          <Button
            variant="contained"
            color="primary"
            onClick={onPauseSub}
            className={styles.pause__button}
          >
            Pause Subscription
          </Button>
        </div>
      )}

      <Typography
        variant={Typographies.BODY_LARGE}
        component="p"
        className={styles.support}
        style={{
          marginBottom: 16,
        }}
      >
        If you having any trouble with Justdone please{' '}
        <button onClick={onSupport} className={styles.link}>
          get in touch
        </button>
        . We’re here to help!
        <br />
        <br />
        Or you can:
      </Typography>

      <Button variant="outlined" color="primary" onClick={onCancelSub}>
        Cancel Subscription
      </Button>
    </div>
  );
};

export default Control;
