import React, { FC, useCallback } from 'react';

import { Typography } from '@mui/material';

import Button from '~/components/atoms/buttons/Button';
import ModalContentTitle from '~/components/atoms/ModalContentTitle';
import useDownsell from '~/modules/cancellation/views/variants/Control/components/DownsellModal/useDownsell';
import analytics from '~/services/analytics';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

const bullets = [
  'Your will lose access to your account and features after current payment period.',
  'If you would restore subscription in the future, prices could be higher.',
];

type Props = {
  onSubmit: () => void;
  onRefuse: () => void;
};

const SubmitCancellation: FC<Props> = ({ onSubmit, onRefuse }) => {
  const { is_stripe_user } = useAppSelector((state) => state.profile);
  const { downsellPlans, invokeDownsell } = useDownsell();

  const handleRefuseClick = (): void => {
    analytics.trackEvent("subscription - ac.don't want cancel 3");
    onRefuse();
  };

  const handleCancelSubClick = useCallback(async (): Promise<void> => {
    analytics.trackEvent('subscription - ac.want cancel 3');

    if (is_stripe_user || !downsellPlans) {
      onSubmit();
      return;
    }

    invokeDownsell({
      downsellPlan: downsellPlans[0],
      onCloseDownsell: onSubmit,
      onSubmitDownsell: onRefuse,
    });
  }, [downsellPlans]);

  return (
    <div className={styles.container}>
      <ModalContentTitle text="Cancel My Subscription" />

      <ul className={styles.list}>
        {bullets.map((el, idx) => (
          <li className={styles.item} key={idx}>
            <Typography
              key={idx}
              variant={Typographies.TITLE_MEDIUM}
              component="p"
            >
              {el}
            </Typography>
          </li>
        ))}
      </ul>

      <Typography
        variant={Typographies.TITLE_MEDIUM}
        component="p"
        className={styles.text}
      >
        Are you sure you want to cancel your subscription?
      </Typography>

      <div className={styles.buttons_container}>
        <Button
          className={styles.button}
          variant="contained"
          color="primary"
          size="medium"
          fullWidth
          onClick={handleRefuseClick}
        >
          Yes, do not cancel
        </Button>

        <Button
          className={styles.button}
          variant="contained"
          color="secondary"
          size="medium"
          fullWidth
          onClick={handleCancelSubClick}
        >
          No, cancel
        </Button>
      </div>
    </div>
  );
};

export default SubmitCancellation;
