import React, { FC, useState } from 'react';

import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';
import clsx from 'clsx';

import Button from '~/components/atoms/buttons/Button';
import IconButton from '~/components/atoms/buttons/IconButton';
import SupportModal from '~/components/molecules/SupportModal';
import { useModalContext } from '~/contexts/modal/ModalContext';
import analytics from '~/services/analytics';
import { colors } from '~/theme/colors';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';
import ChatImg from '../../../../../assets/chat.webp';

const Toast: FC = () => {
  const [isOpened, setIsOpened] = useState(true);
  const { handleOpenModal } = useModalContext();

  const handleToggleToast = (): void => {
    setIsOpened((prev) => !prev);

    analytics.trackEvent(
      `subscription - ac.${isOpened ? 'close' : 'open'} support pop over`,
    );
  };

  const handleSupportClick = (): void => {
    analytics.trackEvent('subscription - ac.click support pop over');

    handleOpenModal({
      component: ({ onClose }) => <SupportModal onClose={onClose} />,
    });
  };

  return (
    <div
      className={clsx(styles.container, {
        [styles.is_opened]: isOpened,
      })}
    >
      <div className={styles.header}>
        <img src={ChatImg} alt="" className={styles.image} />

        <IconButton
          size="small"
          className={styles.button}
          onClick={handleToggleToast}
        >
          <Icon
            icon={
              isOpened
                ? 'material-symbols:close'
                : 'material-symbols:help-outline'
            }
            className={styles.button__icon}
          />
        </IconButton>
      </div>

      <div className={styles.content}>
        <Typography
          variant={Typographies.TITLE_MEDIUM}
          component="h4"
          className={styles.title}
          sx={{ '&&': { marginBottom: '16px' } }}
        >
          Is something wrong?
        </Typography>

        <Typography
          variant={Typographies.BODY_MEDIUM}
          component="p"
          className={styles.text}
          sx={{ '&&': { marginBottom: '18px' } }}
        >
          Before you cancel your subscription, talk to us and give us a chance
          to set things right.
        </Typography>

        <Button
          color="neutral"
          variant="contained"
          fullWidth
          sx={{ '&&': { color: colors.black } }}
          onClick={handleSupportClick}
        >
          Contact Support
        </Button>
      </div>
    </div>
  );
};

export default Toast;
