export enum AB_TESTS_KEYS {
  SUBSCRIPTION_PAGE_LAYOUT = 'sub-page-layout',
  CANCEL_FLOW_V3 = 'cancel-flow-v3',
}

export const AB_TEST_FEATURES: Record<
  AB_TESTS_KEYS,
  {
    key: AB_TESTS_KEYS;
    variants: {
      control: string;
      variant_1: string;
      [key: string]: string;
    };
    defaultVariant: string;
  }
> = {
  [AB_TESTS_KEYS.CANCEL_FLOW_V3]: {
    key: AB_TESTS_KEYS.CANCEL_FLOW_V3,
    variants: {
      control: 'default',
      variant_1: 'reduced',
    },
    defaultVariant: 'default',
  },
  [AB_TESTS_KEYS.SUBSCRIPTION_PAGE_LAYOUT]: {
    key: AB_TESTS_KEYS.SUBSCRIPTION_PAGE_LAYOUT,
    variants: {
      control: 'default',
      variant_1: 'informed',
    },
    defaultVariant: 'default',
  },
};
