// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PPz36SDJZIBxdatMLj5B{display:block;width:100%;margin-bottom:24px}.FYzO0ovfVJH2R4shUN_S{margin-bottom:24px !important}.LvSqrsYEPMc81AlmL0NO{margin-bottom:32px}.TTiNpLzh7SRo5VtNVo07{font-weight:600 !important}`, "",{"version":3,"sources":["webpack://./src/modules/cancellation/views/variants/Control/components/DownsellModal/styles.module.scss"],"names":[],"mappings":"AAEA,sBACE,aAAA,CACA,UAAA,CACA,kBAAA,CAGF,sBACE,6BAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,0BAAA","sourcesContent":["@import \"src/styles/mixins\";\n\n.image {\n  display: block;\n  width: 100%;\n  margin-bottom: 24px;\n}\n\n.title {\n  margin-bottom: 24px !important;\n}\n\n.plans {\n  margin-bottom: 32px;\n}\n\n.button {\n  font-weight: 600 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `PPz36SDJZIBxdatMLj5B`,
	"title": `FYzO0ovfVJH2R4shUN_S`,
	"plans": `LvSqrsYEPMc81AlmL0NO`,
	"button": `TTiNpLzh7SRo5VtNVo07`
};
export default ___CSS_LOADER_EXPORT___;
