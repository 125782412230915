import React, { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import { useModalContext } from '~/contexts/modal/ModalContext';
import { CancellationPath } from '~/modules/cancellation/constants';
import { SCREEN } from '~/modules/cancellation/views/variants/Control';
import DeleteAllDataConfirmationModal from '~/modules/settings/components/modals/DeleteAllDataConfirmationModal';
import DeleteAllDataModal from '~/modules/settings/components/modals/DeleteAllDataModal';
import DeleteAllDataSuccessModal from '~/modules/settings/components/modals/DeleteAllDataSuccessModal';
import SubscriptionCancelRequiredModal from '~/modules/settings/components/modals/SubscriptionCancelRequiredModal';
import { PersonalInformationPath } from '~/modules/settings/constants';
import analytics from '~/services/analytics';
import DateService from '~/services/Date';
import { useActions } from '~/store/hooks/useActions';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { SUBSCRIPTION_STATUS } from '~/types/subscription';

const useDeleteAccount = (): {
  showDeleteAccountModal: () => void;
  isShownDeleteButton: boolean;
} => {
  const profile = useAppSelector((state) => state.profile);
  const { signOut } = useActions();
  const navigate = useNavigate();
  const { handleOpenModal } = useModalContext();

  const isShownDeleteButton = useMemo(() => {
    const isValidStatusToShow = [
      SUBSCRIPTION_STATUS.ACTIVE,
      SUBSCRIPTION_STATUS.CANCELLED,
      SUBSCRIPTION_STATUS.CANCEL_PENDING,
    ].includes(profile.user_subscription.status);

    if (!isValidStatusToShow) {
      return false;
    }

    if (profile.extra_fields.sign_up_at) {
      return (
        Math.abs(
          DateService.differenceInMinutes(
            new Date(),
            new Date(profile.extra_fields.sign_up_at),
          ),
        ) > 120 // 2 hours
      );
    }

    return true;
  }, [profile.user_subscription.status, profile.extra_fields.sign_up_at]);

  const showCancelSubscriptionModal = (): void => {
    analytics.trackEvent('data removal - need cancel before');
    handleOpenModal({
      hideCloseButton: true,
      maxWidth: '460px',
      component: ({ onClose }) => (
        <SubscriptionCancelRequiredModal
          onCancel={onClose}
          onSubmit={() => {
            onClose();
            navigate(
              {
                pathname: CancellationPath,
                search: `?screen=${SCREEN.CANCEL_FORM}`,
              },
              {
                state: {
                  from: PersonalInformationPath,
                },
              },
            );
          }}
        />
      ),
    });
  };
  //
  const showSuccessDeletionAccountModal = (): void => {
    analytics.trackEvent('data removal - removal success');
    handleOpenModal({
      hideCloseButton: true,
      disableBackdropClick: true,
      maxWidth: '460px',
      component: ({ onClose }) => (
        <DeleteAllDataSuccessModal
          onClickGotIt={onClose}
          signOut={signOut}
          closeModal={onClose}
        />
      ),
    });
  };
  //
  const showConfirmDeleteDataModal = (): void => {
    analytics.trackEvent('data removal - confirmation window');
    handleOpenModal({
      hideCloseButton: true,
      maxWidth: '460px',
      component: ({ onClose }) => (
        <DeleteAllDataConfirmationModal
          onCancel={onClose}
          onSuccessDeletion={showSuccessDeletionAccountModal}
        />
      ),
    });
  };

  const showDeleteDataModal = (): void => {
    analytics.trackEvent('data removal - information window');
    handleOpenModal({
      hideCloseButton: true,
      maxWidth: '460px',
      component: ({ onClose }) => (
        <DeleteAllDataModal
          onCancel={onClose}
          onSubmit={showConfirmDeleteDataModal}
        />
      ),
    });
  };
  const showDeleteAccountModal = (): void => {
    if (!isShownDeleteButton) {
      return;
    }

    analytics.trackEvent('general - click delete profile');

    if (
      [
        SUBSCRIPTION_STATUS.CANCELLED,
        SUBSCRIPTION_STATUS.CANCEL_PENDING,
      ].includes(profile.user_subscription.status)
    ) {
      showDeleteDataModal();
    } else {
      showCancelSubscriptionModal();
    }
  };

  return {
    showDeleteAccountModal,
    isShownDeleteButton,
  };
};

export default useDeleteAccount;
